.Sidebar {
  background-color: $bg-700;
  color: $text-10;
  font-weight: $font-weight-500;
  height: 100vh;
  min-width: $Sidebar-minWidth;
  position: fixed;
  right: 0;
  top: 0;
  width: 25%;
}

.Sidebar-header {
  align-items: center;
  box-shadow: 0 1px 0 $border-700;
  display: flex;
  justify-content: space-between;
  min-height: var(--Header-height);
  padding: 0 rem($space-ml);

  .Heading {
    width: 100%;

    .Heading-subtitle { margin: 0; }
    .Heading-subtitle-suffix { display: inline-block; }
  }
}

.Sidebar-header-close {
  color: $text-10;
  margin-left: rem($space);
  margin-right: rem($space * -1);

  &:hover, &:focus {
    color: $text-10;
  }
}

.Sidebar-inner {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--Header-height));
  margin-right: rem($space);
  margin-top: rem($space-ml);
  padding: 0 rem($space-ml) rem($space * 4) rem($space-ml);

  @include mq(xxl) {
    margin-top: rem($space-l);
    padding: 0 rem($space-ml) rem($space * 4) rem($space-ml);
  }
}

.Sidebar-section {
  margin-bottom: rem($space-m);
  margin-right: rem($space * -1);

  &:not(:last-of-type) {
    border-bottom: 1px solid $border-700;
    padding-bottom: rem($space-m);
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &.-l {
    margin-bottom: rem($space-ml);
    padding-bottom: rem($space-ml);
  }
}

.Sidebar-heading {
  @include heading(h3);
  color: $text-10;
}

.Sidebar-text, .Sidebar-link {
  margin-bottom: rem($space-m);
}

.Sidebar-links {
  .Sidebar-link {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.Sidebar-link {
  @include textTruncate();
  @include text($style: textLinkUnderlineNegative);
  display: block;
}

.Sidebar-footer {
  border-top: 1px solid $border-700;
  color: $text-300;
  margin-right: rem($space * -1);
  margin-top: auto;
  padding-top: rem($space-m);
}

.Sidebar-footer-text {
  margin-bottom: rem($space-m);
}
