.Auth {
  background: linear-gradient(to right, $bg-50 50%, $bg-5 50%);
  margin-top: calc(var(--Header-height) * -1);
  position: relative;
  z-index: $Header-zIndex - 1;

  &, .grid, .wrap {
    min-height: 100vh;
  }
}

.Auth-illustrations {
  position: relative;

  .Illustration {
    max-width: $space * 15;
    position: absolute;

    @media (min-height: em(900px)) {
      max-width: $space * 22;
    }

    @include mq(l) {
      width: 50%;

      @media (min-height: em(900px)) {
        max-width: $space * 22;
      }
    }
  }

  .Illustration:first-child {
    bottom: -12%;
    left: rem($space * -5.5);

    path:first-child {
      fill: $accent-4-50;
    }

    @include mq(l) {
      bottom: auto;
      top: 40%;
    }
  }

  .Illustration:nth-child(2) {
    right: rem($space * -3);
    top: 8%;

    path:first-child {
      fill: $accent-2-50;
    }

    @include mq(m) {
      right: 25%;
    }

    @include mq(l) {
      right: 15%;
    }
  }

  .Illustration:nth-child(3) {
    display: none;

    path:first-child {
      fill: $accent-1-50;
    }

    @include mq(m) {
      bottom: -10%;
      display: block;
      right: rem($space * -3);
    }

    @include mq(l) {
      bottom: 3%;
    }
  }

  @include mq($until: l) {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

.Auth-content {
  @include text(l);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Auth-content-inner {
  max-width: rem($space * 24);

  @include mq($until: l) {
    @include boxShadow();
    background-color: $bg-5;
    border-radius: $border-radius;
    padding: rem($space-ml);
    position: relative;
    z-index: 1;
  }

  @include mq(m) {
    width: rem($space * 24);
  }

  @include mq(l) {
    padding-left: rem($space);
  }

  @include mq(xxl) {
    max-width: rem($space * 26);
    width: rem($space * 26);
  }
}

.Auth-text {
  max-width: 95%;
}
