@import '~@biocodeio/components/build/config';

// =========
// Root
// =========

$responsive: true;

// =========
// Wrap, Grid & Container settings
// =========

$grid-m: true;
$grid-l: true;

// =========
// Z Indexes
// =========

$Header-zIndex: 101;
$SidebarLcaInputInfo-zIndex: 103;

// =========
// Header
// =========

$Header-height: $space * 4;
$Header-height-l: $space * 4;

// =========
// Sidebar
// =========

$Sidebar-minWidth: 256px;

