.Header {
  background-color: var(--bg-5);
  box-shadow: 0 1px 0 var(--border-100);
  position: relative;
  z-index: $Header-zIndex;
}

.Header-wrap.wrap {
  align-items: center;
  display: flex;
  max-width: none;
  min-height: var(--Header-height);
  position: relative;
  top: rem(-1px);
}

.Header-logo {
  @include text(l, headingLink);
  font-weight: $font-weight-500;
}

.Header-nav-link {
  @include text($style: textLink);
  display: block;
}

.Header-nav {
  margin-left: auto;

  > ul {
    @include resetList();
    align-items: center;
    display: flex;
  }
}

.Header-nav-item {
  display: none;

  &:not(:last-child) {
    margin-right: rem($space-ml);
  }

  @include mq(m) {
    display: block;
  }
}

.Header-nav-item--menu {
  display: block;

  .Dropdown {
    @include mq($until: s) {
      position: static;

      &, &.-alignRight {
        .Dropdown-menu {
          left: var(--wrap-spaceLeft);
          min-width: auto;
          right: var(--wrap-spaceRight);
        }
      }
    }
  }
}

.Header-nav-item-dropdown-item {
  display: block;

  @include mq(m) {
    display: none;
  }
}


/* ======
 * Modifiers
 * ====== */

.Header {
  &.is-transparent {
    background-color: transparent;
  }

  &.is-plain {
    background-color: transparent;
    box-shadow: none;
  }
}
