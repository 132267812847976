.View--home {
  .View-header {
    background-color: var(--bg-100);
    margin-top: calc(var(--Header-height) * -1);
    padding-bottom: rem($space * 3);
    padding-top: rem($space * 5);
    text-align: center;

    @include mq(m) {
      padding-bottom: rem($space * 4);
      padding-top: rem($space * 6);
    }
  }

  .View-header-heading {
    margin-left: auto;
    margin-right: auto;
    max-width: rem($space * 40);
  }

  .View-header-text {
    margin-left: auto;
    margin-right: auto;
    max-width: rem($space * 32);

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

