.App {}

.App-loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 100vw;
}
